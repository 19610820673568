// Lazy load components only when they're used on the page
// This improves initial page load performance

// Accordion
if (document.querySelector('drop-down') || document.querySelector('accordion-group')) {
  import('./accordion.js');
}

// Complementary Products
if (document.querySelector('complementary-products')) {
  import('./complementary-products.js');
}

// Featured Product Slider
if (document.querySelector('featured-product-slider')) {
  import('./featured-product-slider.js');
}

// Card Promo Text
if (document.querySelector('card-promo-text')) {
  import('./card-promo-text.js');
}

// Reveal Articles
if (document.querySelector('reveal-articles')) {
  import('./reveal-articles.js');
}

// Sticky ATC
if (document.querySelector('sticky-atc')) {
  import('./sticky-atc.js');
}

// Swym Wishlist
if (document.querySelector('swym-custom-wishlist')) {
  import('./swym-wishlist.js');
}

// Text Reveal
if (document.querySelector('text-reveal')) {
  import('./text-reveal.js');
}

// Featured Product Scroll
if (document.querySelector('featured-product-scroll')) {
  import('./featured-product-scroll.js');
}

// Timeline Reveal is loaded in the footer (kept in assets folder)
